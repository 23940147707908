import React from 'react';
import { useMount } from 'react-use';
import { useQueryParams } from 'hooks';

const PaymentCallback = () => {
  const params = useQueryParams();

  useMount(() => {
    if (!window.parent) {
      return;
    }
    const data = {
      type: 'paymentAuthorized',
      payment: {
        transactionId: params.pid,
        orderId: params.orderId,
        result: params.result,
      },
    };
    window.parent.postMessage(data, window.location.origin);
  });

  return <div />;
};

export default PaymentCallback;
